@import '~antd/dist/antd.css';

body {
  margin: 0;
  font-family: Nunito;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: Nunito;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.site-layout-sub-header-background {
  background: #fff;
}

.site-layout-background {
  background: #fff;
}
.ant-layout-sider-children,
.ant-layout {
  background: #fff;
}

#components-layout-demo-responsive .logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;
}

.ant-layout-content-static {
  position: static;
  padding-top: 40px;
}

.ant-layout-header {
  width: 87.5%;
  z-index: 1000;
  top: 0;
}

.connection-error a {
  color: #40a9ff;
}

.site-page-header {
  border: none;
}

.ant-page-header-back {
  margin-right: 8px;
  font-size: 16px;
  line-height: 2;
}

.ns-bg-light-white {
  background-color: #f7f7f7;
}

.antd-font {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
    'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
    'Noto Color Emoji';
}

.ns-text-light-white {
  color: #f7f7f7;
}

.ant-statistic-title {
  margin-bottom: 4px;
  color: rgba(38, 38, 38, 0.85);
  font-size: 22px;
  font-weight: bold;
}

.personal-statistic {
  position: relative;
}

.personal-statistic .ant-statistic-title {
  font-size: 18px;
  font-weight: bold;
}

.personal-statistic .ant-card-body {
  padding: 6px;
}

.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
  border-right: none;
}

.ant-layout-sider-children {
  border-right: 0.5px solid #e2e8f0;
}

.custom-class {
  height: 500px;
  width: 800px;
  white-space: pre-wrap;
}
.Spam-Rate {
  /* width: 152px; */
  height: 41px;
  font-family: Nunito;
  font-size: 26px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: rgba(38, 38, 38, 0.85);
}

.ant-statistic-content-value {
  display: inline-block;
  direction: ltr;
  color: #60c72a;
  font-size: 32px;
  font-weight: 500;
}
.personal-statistic .ant-statistic-content-value {
  font-size: 28px;
  font-weight: 500;
}

.individual-statistic .ant-statistic-content-value {
  background-color: #f7f7f7;
  border-radius: 12px;
  padding: 11px 52px;
  margin-top: 52px;
}

.ant-layout-header .ant-page-header-heading-title {
  font-size: 30px;
  line-height: 39px;
}

.ant-menu-item-last-child {
  margin-top: 525px;
}

.ant-menu-sub {
  overflow-y: auto;
  height: 100vh;
}

.v-align-3 .anticon {
  vertical-align: 3px;
}
.v-align-2 .anticon {
  vertical-align: 2px;
}

.ns-content-section {
  min-height: 325px;
}

.ns-sider-min-height {
  min-height: 750px;
}

.signup-img {
  height: 800px;
  background-color: #e9f0f6;
}

.Welcome-Please-sign {
  width: 375px;
  height: 24px;
  font-family: Nunito;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #505050;
}

.On-Allegrow-weve-s {
  width: 375px;
  height: 72px;
  font-family: Nunito;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #505050;
}

.ant-modal-content {
  border-radius: 25px;
}

.ant-modal-close {
  display: none;
}

.please-type-in-your {
  color: #505050;
}

.ns-absolute {
  position: absolute !important;
}

.ns-green {
  color: #60c72a;
}

.ns-bg-green {
  background-color: #60c72a;
}

.ns-border-green {
  border: #60c72a;
}

.ns-bg-green-dark {
  background-color: #207402 !important;
}

.ns-bd-green {
  border: solid 3px #60c72a !important;
}

.ns-bg-green:hover,
.ns-bg-green:focus {
  background-color: #a0d468;
}

.ns-bg-green .ant-btn:hover,
.ns-bg-green .ant-btn:focus {
  background-color: #60c72a;
}

.ns-gray {
  color: #8c8c8c;
}

.ns-w-275 {
  width: 275px;
}

.ns-shadow-xl {
  -webkit-box-shadow: 5px 3px 20px 3px rgba(219, 219, 219, 0.79);
  -moz-box-shadow: 5px 3px 20px 3px rgba(219, 219, 219, 0.79);
  box-shadow: 5px 3px 20px 3px rgba(219, 219, 219, 0.79);
}

.ns-shadow-xl-skip-top {
  -webkit-box-shadow: 5px 0px 20px 3px rgba(219, 219, 219, 0.79);
  -moz-box-shadow: 5px 0px 20px 3px rgba(219, 219, 219, 0.79);
  box-shadow: 5px 12px 20px 3px rgba(219, 219, 219, 0.79);
}

.ns-shadow-white-xl {
  -webkit-box-shadow: 5px 3px 20px 3px rgba(245, 245, 245, 0.79);
  -moz-box-shadow: 5px 3px 20px 3px rgba(245, 245, 245, 0.79);
  box-shadow: 5px 3px 20px 3px rgba(245, 245, 245, 0.79);
}
.ns-rounded-lg {
  border-radius: 25px !important;
}

.ant-popover-inner {
  border-radius: 25px !important;
}

.ns-rounded-bl-lg {
  border-bottom-left-radius: 25px;
}

#components-layout-demo-responsive .logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;
}

#root .logo {
  float: left;
  animation: normal;
}

.line-chart {
  animation: fadeIn 600ms cubic-bezier(0.57, 0.25, 0.65, 1) 1 forwards;
  opacity: 0;
}

.line-chart-aspect-ratio {
  height: 0;
  padding-bottom: 25%;
  cursor: pointer;
}

.activeChartCard {
  border: solid 3px #69bb24;
}

.chartCard .ant-card-bordered:not(.activeChartCard) {
  border: solid 3px #fff;
}

/* Collaspse Panel */
.collapse-panel-title span:not(.ant-collapse-content-inactive) {
  margin-top: 4px;
}

.collapse-panel-title {
  width: 90%;
  overflow: initial;
}

.ant-collapse-header span {
  float: left;
  font-size: 20px;
  font-weight: bold;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: rgba(38, 38, 38, 0.85);
}

.ant-collapse-header {
  display: flex;
  align-items: flex-start;
}

.collapse-panel-content {
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #505050;
}

.collapse-panel-content-archived {
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: rgba(191, 189, 189, 0.85) !important;
}

.collapse-panel-content li {
  list-style: inside;
}

.collapse-panel-content ol li {
  list-style-type: auto;
}
.collapse-panel-content a {
  font-size: 15px;
}

.collapse-panel-content .bold {
  color: #000;
}

.collapse-panel-content blockquote {
  background: #f9f9f9;
  border-left: 10px solid #ccc;
  margin: 1.5em 10px;
  padding: 0.5em 10px;
  quotes: '\201C''\201D''\2018''\2019';
}

.collapse-panel-content blockquote:before {
  color: #ccc;
  content: open-quote;
  font-size: 4em;
  line-height: 0.1em;
  margin-right: 0.25em;
  vertical-align: -0.4em;
}

.collapse-panel-content blockquote p {
  display: inline;
}

.collapse-panel-content :is(h1, h2) {
  font-size: revert;
}

.ant-collapse-extra {
  width: 10%;
  display: flex;
  align-items: end;
  justify-content: flex-end;
}

.ant-collapse-arrow {
  margin-top: 0.3rem;
}

.ant-collapse-extra button {
  display: none;
}

.ant-collapse-item-active .ant-collapse-extra button {
  display: block;
}

.ns-mb-0 {
  margin-bottom: 0px !important;
}

.ns-p-0 {
  padding: 0px !important;
}

.herder-div-width {
  width: calc(100% - 200px);
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.connect-mailbox-modal {
  transform-origin: center !important;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.screen-middle-public {
  height: 65vh;
  display: grid;
  align-content: center;
}

.stick-bottom-footer {
  position: absolute;
  display: block;
  margin-left: 40%;
}

.ant-message .anticon {
  top: -3px;
}

.space-align-container {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}

.space-align-block {
  margin: 8px 4px;
  border: 1px solid #40a9ff;
  padding: 4px;
  flex: none;
}

.space-align-block .mock-block {
  display: inline-block;
  padding: 32px 8px 16px;
  background: rgba(150, 150, 150, 0.2);
}

.h-500 {
  height: 500px;
}
.min-h-500 {
  min-height: 500px;
}

.ns-bottom {
  position: absolute;
  bottom: 35px;
}

.ns-right-12 {
  right: 12%;
}

.ns-left-10 {
  left: 10%;
}
.ns-left-5 {
  left: 5%;
}

.password-info {
  font-size: 13px;
}

.Reputation {
  font-family: Nunito;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 3;
  letter-spacing: normal;
  color: #a13cc8;
}

.clef-replies,
.clef-emails {
  width: 102px;
  color: #ff6a00;
}

.dorian-emails {
  width: 82px;
  color: #353fc3;
}

.dorian-replies {
  color: #51b200;
}

.dorian-replies-important {
  color: #51b200 !important;
}

.motif-emails {
  width: 91px;
  color: #4cb8c4;
}

.info-emails {
  width: 20px;
  margin: 4px 364px 25px 20px;
  color: #a6a6a6;
  display: inline-grid;
  position: absolute;
  padding-top: 14px;
}

.multi-type-emails span {
  height: 18px;
  padding-left: 8px;
}
.Allegrow-Email-Categ {
  margin: 0 480px 8px 22px;
  font-size: 17px;
  color: #505050;
}

.The-Allegrow-platforMargin {
  width: auto;
  margin: 17px 43px 0 22px;
  font-size: 16px;
}
.The-Allegrow-platfor {
  margin: 5px 10px 0 22px;
  font-size: 15px;
  color: #505050;
}

.The-Allegrow-platfor .text-style-1 {
  font-size: 16px;
  font-weight: bold;
  color: #000;
}

.ant-input-textarea-show-count::after {
  content: attr(data-words) ' words, ' attr(data-count) ' characters';
}

.ant-popover-title {
  min-height: 42px;
  padding: 10px 16px 4px;
}

.spamRateCard {
  color: #ac105c;
}

.chartjs-size-monitor {
  z-index: 9999 !important;
}

#tooltip {
  opacity: 1;
  position: absolute;
  background: rgba(0, 0, 0, 0.7);
  color: white;
  border-radius: 3px;
  -webkit-transition: all 0.1s ease;
  transition: all 0.1s ease;
  pointer-events: none;
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  padding: 4px;
}

#tooltip td {
  text-align: left;
}

.chartjs-tooltip-key {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-right: 10px;
}

.create-content-tests .ant-statistic-content-prefix {
  margin-right: 0px;
}
.create-content-tests .ant-statistic-content-prefix .ant-form-item {
  margin-bottom: 0px;
}

.mailbox-shrink h4,
.mailbox-shrink div div {
  text-align: left;
  margin-left: 12%;
}

.ns-cursor-disabled {
  color: #000 !important;
  background-color: #fff !important;
}

.ant-picker-dropdown-placement-bottomRight {
  top: 207px !important;
}

.ant-statistic-content-prefix input {
  width: 35px;
  font-size: 26px;
  padding: 0px;
  color: rgba(38, 38, 38, 0.85);
  font-weight: 200;
}

.create-content-tests .statistics-percentage-card .ant-statistic-content-value {
  position: absolute;
  left: 57%;
  margin-top: -2px;
}
.chartCardContent .variat_1 .ant-statistic-content-value {
  color: #ff6a00 !important;
}

.statistics-joined-card .ant-statistic-content-value {
  font-size: 26px;
  font-weight: 200;
  color: #262626d9 !important;
}

.statistics-joined-card .ant-statistic-content-value:not(.statistics-joined-card-seleced) {
  /* border: solid 2px #e2e8f0; */
  border-radius: 15px;
  padding: 2px;
}

.statistics-joined-card-seleced .ant-statistic-content-value {
  border: 1px solid #51b200;
  border-radius: 15px;
  padding: 2px;
}

.statistics-joined-card .ant-card-body {
  display: flex;
  justify-content: space-between;
}

.ant-picker-suffix,
.ant-picker-active-bar {
  display: none !important;
}

.date-range-picker input {
  font-weight: 200;
  font-size: 26px;
  text-align: center;
}

.date-range-card .ant-form-item-control {
  margin: 0 !important;
}

.date-range-picker .ant-picker-input input {
  cursor: pointer;
}

#control-hooks_variat_a_subject,
#control-hooks_variat_b_subject,
#control-hooks_variat_a_body,
#control-hooks_variat_b_body {
  border-radius: 5px;
}
.variations-title {
  font-size: 25px;
  font-weight: 500;
}
.variations-title.variat_0 {
  color: #51b200 !important;
}

.variations-title.variat_1 {
  color: #ff6a00 !important;
}

.save-as-draft {
  display: flex;
  float: left;
}

.create-content-tests .ant-select-selector {
  border-radius: 5px !important;
  height: 40px !important;
  font-size: 16px;
  text-align: left;
  padding-top: 4px !important;
  cursor: pointer !important;
}

.variations-cal div {
  /* padding-left: 0px; */
  flex: 1;
}

/* .variations-cal-head {
  float: right;
  width: 47%;
} */

.ant-tooltip-inner-error .ant-tooltip-inner {
  border-radius: 0.375rem;
}

.ns-pd-r-50 {
  padding-right: 50px;
}

/* .variations-cal-head .variations-reputation, .variations-cal-head .variations-spam-rate, .variations-cal-head .variations-difference{
  text-align: center;
} */

/* .variations-cal-head .variations-email-sent{
  padding-left: 8%;
} */

/* .variations-cal-head .variations-reputation{
  padding-left: 6%;
}

.variations-cal-head .variations-spam-rate{
  padding-left: 3%;
} */

.variations-cal-head div {
  color: #262626a8;
  font-size: 16px;
  font-weight: 200;
  /* width: 100px; */
  flex: 1;
  text-align: center;
}

.variations-cal div {
  /* display: flex;
  width: 80px;
  align-items: center; */
  color: #262626a8;
  font-size: 22px;
  font-weight: 500;
  flex: 1;
  text-align: center;
}

/* .lab-icon .ant-menu-item-selected{
  fill:#e2e8f0
}

.lab-icon .ant-menu-item-selected{
  fill:#e2e8f0
} */

.my-profile .ant-input-affix-wrapper {
  border-radius: 0.5rem;
  height: 34px;
  padding: 4px 4px 4px 0px;
}

.my-profile .ant-select-selector {
  cursor: pointer !important;
}

.my-profile
  .ant-form-item-has-error
  .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input)
  .ant-select-selector {
  border: #fff !important;
}

.green-circle {
  position: absolute;
  bottom: 58%;
  left: 50%;
  transform: translate(-50%, 50%);
  width: 30%;
  height: 60%;
  background-color: #06d6a0;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.circle-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #ffffff;
  font-size: 1.5vw;
  text-align: center;
}

.pie-chart-container {
  position: relative;
  z-index: 10;
}

.clear-overlay {
  position: absolute;
  bottom: 58%;
  left: 50%;
  transform: translate(-50%, 50%);
  width: 22%;
  height: 22%;
  border-radius: 50%;
  z-index: 3;
  cursor: pointer;
  background-color: transparent;
}

.recommendation-list ul {
  list-style-type: disc; /* Default bullet points */
  margin-left: 20px; /* Add some indentation for the list */
}

.recommendation-list ul li {
  margin-bottom: 10px; /* Optional: Add some space between list items */
}

.no-select {
  user-select: none; /* Standard syntax */
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE 10+ and Edge */
}

.custom-spinner-white .ant-spin-dot-item {
  background-color: white;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(2rem);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInTimelineCard {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeInSubMenu {
  0% {
    opacity: 0;
    transform: translateX(2rem);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeInLeftMenu {
  0% {
    opacity: 0;
    transform: translateX(2rem);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.ns-red-1 {
  color: #c53030 !important;
}

@keyframes fadeOut {
  100% {
    opacity: 0;
    transform: translateY(2rem);
  }
  0% {
    opacity: 1;
    transform: translateY(0);
  }
}

.fadeInTimelineCard {
  animation: fadeInTimelineCard 1s ease-in-out;
}

.ns-menu-5 {
  animation: fadeIn 0.5s ease-in-out;
}

.ns-menu-10 {
  animation: fadeIn 0.3s ease-in-out;
}

.ns-sub-menu {
  animation: fadeInSubMenu 1s ease-in-out;
}

.ns-red-1 {
  color: #c53030 !important;
}

.ns-backdrop-blur {
  backdrop-filter: blur(6px);
}

.ns-blur {
  filter: blur(6px);
}

.settings-screen .ant-form-item-control-input-content {
  max-width: none;
}

.settings-screen .ant-form-horizontal {
  padding-left: 1.25rem;
  text-align: left;
}

.settings-screen .ant-form-item-label {
  text-align: left;
}

@media (min-width: 1024px) {
  .mailbox-shrink h4,
  .mailbox-shrink div div {
    margin-left: 12%;
  }
}

@media (min-width: 1024px) {
  .settings-screen .ant-form-item-label {
    min-width: 12.5rem;
  }
  .settings-screen input {
    min-width: 18.75rem;
  }
  .settings-screen #control-hooks_phone_number {
    min-width: 12.5rem;
  }
  .settings-screen input.team-details-min-width {
    min-width: 0;
  }
}

@media (max-width: 1024px) {
  .settings-screen .ant-form-item-label,
  .settings-screen .ant-form-item-control {
    min-width: 45%;
  }
}

@media (min-width: 1800px) {
  .mailbox-shrink h4,
  .mailbox-shrink div div {
    margin-left: 14%;
  }
}
